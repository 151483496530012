'use strict';

//VARIABLES
const tabPanel = document.querySelector('#my-search-tab-panel');

//INICIALIZADOR
document.addEventListener('DOMContentLoaded', function(e){
    startEvents();
});


//START METHODS
function startEvents(){
  //tabPanel.addEventListener('click', showTab);
}


//METHODS

function showTab(e){
  $(this).tab('show');
}